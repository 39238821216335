import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { sanitizer, useCSSMotion } from '../../util';
import moment from 'moment';
import classnames from 'classnames';
import UniversalImage from '../UniversalImage/UniversalImage';

import './LiveDataTracker.scss';

const LiveDataTracker = ({
  data: { title, lastUpdatedCopyBefore, cardOneIcon, cardTwoIcon, cardOneCopy, cardTwoCopy },
}) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        liveDataTracker {
          liveDataTracker {
            dateUpdated
            diemInCirculation
            totalAggregate
          }
        }
      }
    }
  `);
  const contentRef = useRef(null);
  const contentMotionState = useCSSMotion(contentRef);

  const liveData = wp.liveDataTracker?.liveDataTracker;
  const momentLastUpdatedTime = moment.utc(liveData.dateUpdated, 'YYYY-MM-DD hh:mm:ss');

  // TODO: fetch dynamically instead.

  // const [liveData, setLiveData] = useState(wp.liveDataTracker?.liveDataTracker || {});
  // fetch('https://www.diem.com/graphql', {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({
  //     query: `
  //   query {
  //     liveDataTracker {
  //       liveDataTracker {
  //         averageGasPrices
  //         dateUpdated
  //         diemInCirculation
  //         totalAggregate
  //       }
  //     }
  //   }`,
  //   }),
  // })
  //   .then((res) => res.json())
  //   .then((res) => console.log(res.data));

  return (
    <section className={classnames('LiveDataTracker', contentMotionState)} ref={contentRef}>
      <div className="container">
        <div className="header">
          <h2 className="title">{title}</h2>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: `${sanitizer(lastUpdatedCopyBefore)} ${momentLastUpdatedTime.fromNow()}`,
            }}
          />
        </div>
        <div className="CardContainer">
          <div className="CardItem">
            <UniversalImage className="icon" image={cardOneIcon} />
            <span className="live-data">{liveData.diemInCirculation}</span>
            <p className="description">{cardOneCopy}</p>
          </div>
          <div className="CardItem">
            <UniversalImage className="icon" image={cardTwoIcon} />
            <span className="live-data">{liveData.totalAggregate}</span>
            <p className="description">{cardTwoCopy}</p>
          </div>
        </div>
        <div className="waves-container" />
      </div>
    </section>
  );
};

export const fragments = graphql`
  fragment LiveDataTracker on WpPage_Flexiblelayout_FlexibleChildren_LiveDataTracker {
    title
    lastUpdatedCopyBefore
    cardOneIcon {
      ...Icon
    }
    cardTwoIcon {
      ...Icon
    }
    cardOneCopy
    cardTwoCopy
    id
    jumpToLinkTitle
    name
  }

  fragment LiveDataTracker_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_LiveDataTracker {
    title
    lastUpdatedCopyBefore
    cardOneCopy
    cardTwoCopy
    cardOneIcon {
      ...Icon
    }
    cardTwoIcon {
      ...Icon
    }
    id
    jumpToLinkTitle
    name
  }
`;

export default LiveDataTracker;
